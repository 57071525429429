
import ApiService from "@/core/services/ApiService"
import { defineComponent, onMounted, ref } from "vue"
import { Edit } from "@element-plus/icons-vue"
import { DateFormatTypes, dateForm } from "@/core/helpers/dateformat"
import { SelectModel } from "@/core/models/SelectModel"
import { Transfer } from "@/core/models/Transfer"

import { FormInstance } from "element-plus"
import { getRule, RuleTypes } from "@/core/helpers/rules"
import { CashBookListItem } from "@/core/models/CashBookListItem"
import { computed } from "@vue/reactivity"
import swalConfirm from "@/core/helpers/swal-confirm"
import ElInputCurrency from "@/components/input/ElInputCurrency.vue"
import { currencyForm } from "@/core/helpers/currencyformat"

export default defineComponent({
  name: "checkout",
  components: { ElInputCurrency },
  setup() {
    const isBusinessSelectLoading = ref(false)
    const isBranchSelectLoading = ref(false)
    const isBusinessCashBookSelectLoading = ref(false)
    const isBranchCashBookSelectLoading = ref(false)

    const businessList = ref<SelectModel[]>([])
    const branchList = ref<SelectModel[]>([])
    const businessCashBookList = ref<CashBookListItem[]>([])
    const branchCashBookList = ref<CashBookListItem[]>([])

    const transferData = ref<Transfer>({} as Transfer)

    const activeBusinessId = ref<string>()
    const activeBranchId = ref<string>()
    const ruleFormRef = ref<FormInstance>()

    const rules = ref({
      fromCashBookId: getRule(RuleTypes.SELECT, "Şube Hesap"),
      toCashBookId: getRule(RuleTypes.SELECT, "Ana Kasa Hesap"),
      amount: getRule(RuleTypes.NUMBER, "Tutar"),
      description: getRule(RuleTypes.TEXT100, "Açıklama"),
    })

    onMounted(async () => {
      await getBusinessList()
    })

    const getBusinessList = async () => {
      isBusinessSelectLoading.value = true
      const { data } = await ApiService.get("business")
      businessList.value = data.filter(x => x.isActive).map(x => ({ id: x.id, name: x.title }))
      if (businessList.value.length == 1) {
        activeBusinessId.value = businessList.value[0].id
        OnChangeBusiness(activeBusinessId.value)
      }
      isBusinessSelectLoading.value = false
    }

    //TODO: should change as dropdown values id to object and remove get balance methods
    const getBranchAccountBalance = computed(() => {
      const branchCashbook = branchCashBookList.value.find(
        x => x.id == transferData.value.fromCashBookId
      )
      return branchCashbook?.balance
    })

    //TODO: should change as dropdown values id to object and remove get balance methods
    const getBusinessAccountBalance = computed(() => {
      const businessCashbook = businessCashBookList.value.find(
        x => x.id == transferData.value.toCashBookId
      )
      return businessCashbook?.balance
    })

    const getBranchList = async businessId => {
      isBranchSelectLoading.value = true
      const config = {
        params: {
          businessId: businessId,
        },
      }
      const { data } = await ApiService.query("branch", config)
      isBranchSelectLoading.value = false
      branchList.value = data.filter(x => x.isActive).map(x => ({ id: x.id, name: x.title }))
    }

    const getBusinessCashBookList = async businessId => {
      isBusinessCashBookSelectLoading.value = true
      activeBusinessId.value = businessId != undefined ? businessId : activeBusinessId.value
      const { data } = await ApiService.get("cash-book/business/" + activeBusinessId.value)
      isBusinessCashBookSelectLoading.value = false
      businessCashBookList.value = data
      if (businessCashBookList.value.length == 1) {
        transferData.value.toCashBookId = businessCashBookList.value[0].id
      }
    }

    const getBranchCashBookList = async branchId => {
      isBranchCashBookSelectLoading.value = true
      activeBranchId.value = branchId != undefined ? branchId : activeBranchId.value
      const { data } = await ApiService.get("cash-book/branch/" + activeBranchId.value)
      isBranchCashBookSelectLoading.value = false
      const businessCashbook = businessCashBookList.value.find(
        x => x.id == transferData.value.toCashBookId
      )
      branchCashBookList.value = data.filter(x => x.currencyUnit == businessCashbook?.currencyUnit)
    }

    function addTransfer(formEl: FormInstance | undefined) {
      if (!formEl) return
      formEl.validate(async valid => {
        if (valid) {
          const confirmResult = await swalConfirm()
          if (confirmResult.isConfirmed)
            await ApiService.post("cash-book/transfer", transferData.value)

          transferData.value = {
            toCashBookId: transferData.value.toCashBookId,
            fromCashBookId: transferData.value.fromCashBookId,
          } as Transfer
          await getBusinessCashBookList(activeBusinessId.value)
          await getBranchCashBookList(activeBranchId.value)
        }
      })
    }

    const OnChangeBusiness = async businessId => {
      activeBranchId.value = undefined
      transferData.value.fromCashBookId = undefined
      transferData.value.toCashBookId = undefined
      await getBusinessCashBookList(businessId)
      await getBranchList(businessId)
    }

    const OnChangeBusinessCashBook = async () => {
      transferData.value.fromCashBookId = undefined
      if (activeBranchId.value) await getBranchCashBookList(activeBranchId.value)
    }

    const OnChangeBranch = async branchId => {
      transferData.value.fromCashBookId = undefined
      await getBranchCashBookList(branchId)
    }

    return {
      dateForm,
      DateFormatTypes,
      isBusinessCashBookSelectLoading,
      isBranchCashBookSelectLoading,
      branchCashBookList,
      businessCashBookList,
      businessList,
      branchList,
      activeBusinessId,
      activeBranchId,
      Edit,
      getBusinessCashBookList,
      getBranchCashBookList,
      getBranchAccountBalance,
      getBusinessAccountBalance,
      isBusinessSelectLoading,
      isBranchSelectLoading,
      OnChangeBusiness,
      OnChangeBusinessCashBook,
      OnChangeBranch,
      addTransfer,
      currencyForm,
      ruleFormRef,
      transferData,
      rules,
    }
  },
})
